import { useContext, useState } from "react";
import { userUtils } from "../../utils/userUtils";
import { Button } from "../Button";
import { Textbox } from "../Textbox";
import { UserContext } from "../context/UserContext";
import { useNavigate } from "react-router-dom";
import { FlashContext } from "../context/FlashContext";
import { Logo } from "../Logo";

export const LoginPage = () => {
    const [userContext, dispatch] = useContext(UserContext);
    const [flashContext, flashDispatch] = useContext(FlashContext);
    const navigate = useNavigate();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!username || !password) {
            return;
        }

        setIsLoading(true);

        const response = await userUtils.login({ username, password });

        if (response?.status === 200) {
            localStorage.setItem("ar-jwt", response.data.data.jwt);

            dispatch({ type: "SET_USER", payload: response.data.data.user });

            navigate("/play");
        }

        setIsLoading(false);
    };

    return (
        <div className="flex justify-center pt-16 px-8">
            <div className="w-72">
                <Logo primary className="mb-4" />
                <form onSubmit={handleSubmit} className="space-y-4">
                    <Textbox
                        label={"Username or E-Mail"}
                        placeholder={"Username or E-Mail"}
                        onChange={(e) => setUsername(e.target.value)}
                        value={username}
                    />
                    <Textbox
                        label={"Password"}
                        placeholder={"Password"}
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                    />
                    <div className="text-center">
                        <Button
                            type="submit"
                            disabled={!username || !password}
                            isLoading={isLoading}
                            className="w-full"
                        >
                            Login
                        </Button>
                        <p className="my-2 text-tertiary">or</p>
                        <Button variant="secondary" className="w-full">
                            Create account
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
};
