import { useContext } from "react";
import { Navigator, NavigatorPanel, NavigatorTab } from "../Navigator";
import { AdvertiserUpdateForm } from "../forms/AdvertiserUpdateForm";
import { CampaignList } from "../lists/CampaignList";
import { useTranslation } from "react-i18next";
import { NSlashM } from "../NSlashM";
import { UserContext } from "../context/UserContext";

export const AdvertisementsNavigator = ({ ...props }) => {
    const { t } = useTranslation();
    const [userContext] = useContext(UserContext);

    return (
        <Navigator {...props}>
            <NavigatorTab panelId="campaigns">
                {t("title.your_campaigns")}
            </NavigatorTab>
            <NavigatorTab panelId="settings">
                {t("title.settings")}
            </NavigatorTab>
            <NavigatorTab panelId="how-it-works">
                {t("title.how_it_works")}
            </NavigatorTab>
            <NavigatorTab panelId="guidelines">
                {t("title.guidelines")}
            </NavigatorTab>
            <NavigatorTab panelId="ad-policy">
                {t("title.ad_policy")}
            </NavigatorTab>
            <NavigatorPanel
                title={t("title.your_campaigns")}
                id="campaigns"
                rightContent={
                    <NSlashM
                        n={userContext?.user?.advertiser?.campaignCount}
                        m={5}
                    />
                }
            >
                <CampaignList
                    sort="campaign.status"
                    sortDirection="descending"
                    uuidAdvertiser={userContext?.user?.advertiser?.uuid}
                />
            </NavigatorPanel>
            <NavigatorPanel title={t("title.how_it_works")} id="how-it-works">
                <section class="mb-8">
                    <h2 class="text-lg font-bold text-primary mb-2">
                        Step 1: Create Your Ad Video
                    </h2>
                    <p class="text-secondary">
                        Start by crafting your{" "}
                        <strong>compelling advertisement video</strong>. This is
                        your chance to showcase your product or service in a
                        visually appealing and engaging manner. Think about what
                        sets you apart from the competition and highlight those
                        unique selling points. Remember, a well-made video can
                        captivate your audience and leave a lasting impression.
                        Need inspiration? Check out some{" "}
                        <a href="#" class="text-primary">
                            examples of successful ad videos
                        </a>{" "}
                        to get your creative juices flowing.
                    </p>
                    <ul class="list-disc ml-6 text-secondary">
                        <li>Focus on a clear and concise message</li>
                        <li>Use high-quality visuals and audio</li>
                        <li>Include a strong call-to-action</li>
                        <li>Keep it relevant to your target audience</li>
                    </ul>
                </section>

                <section class="mb-8">
                    <h2 class="text-lg font-bold text-primary mb-2">
                        Step 2: Submit Your Video
                    </h2>
                    <p class="text-secondary">
                        Once your video is ready, it's time to{" "}
                        <strong>submit it to our platform</strong>. Our
                        submission process is simple and straightforward. Just
                        fill out the necessary details, such as your target
                        audience demographics and campaign objectives. Don't
                        forget to include any specific instructions or
                        preferences you have for your advertisement. Need help
                        getting started? Our{" "}
                        <a href="#" class="text-primary">
                            submission guidelines
                        </a>{" "}
                        provide useful tips and advice to ensure your video
                        meets our requirements.
                    </p>
                    <ul class="list-disc ml-6 text-secondary">
                        <li>
                            Provide accurate information about your campaign
                            goals
                        </li>
                        <li>
                            Upload your video in the recommended format and size
                        </li>
                        <li>Double-check your submission before finalizing</li>
                        <li>
                            Reach out to our support team for assistance if
                            needed
                        </li>
                    </ul>
                </section>

                <section class="mb-8">
                    <h2 class="text-lg font-bold text-primary mb-2">
                        Step 3: Join the Raffle
                    </h2>
                    <p class="text-secondary">
                        Your submitted video will automatically be entered into
                        our <strong>regular raffle draws</strong>. These draws
                        are held periodically, giving you multiple chances to
                        win. By participating in the raffle, you're opening up
                        your advertisement to a wide audience. This increases
                        your visibility and potential customer reach. Want to
                        learn more about our raffle process? Check out our{" "}
                        <a href="#" class="text-primary">
                            FAQ section
                        </a>{" "}
                        for answers to commonly asked questions.
                    </p>
                    <ul class="list-disc ml-6 text-secondary">
                        <li>Stay updated on upcoming raffle dates</li>
                        <li>
                            Share your excitement with your audience to generate
                            buzz
                        </li>
                        <li>Monitor your email for notifications if you win</li>
                        <li>
                            Review the terms and conditions for raffle
                            participation
                        </li>
                    </ul>
                </section>

                <section class="mb-8">
                    <h2 class="text-lg font-bold text-primary mb-2">
                        Step 4: Win Ad Space
                    </h2>
                    <p class="text-secondary">
                        If your video is selected in the raffle,{" "}
                        <strong>congratulations!</strong> You've won coveted ad
                        space on our platform. Your advertisement will be
                        prominently featured, ensuring maximum exposure to our
                        audience. This exposure can lead to increased brand
                        awareness, website traffic, and ultimately, more sales
                        for your business. Curious about the benefits of winning
                        ad space? Read our{" "}
                        <a href="#" class="text-primary">
                            success stories
                        </a>{" "}
                        to see how other advertisers have benefited from our
                        platform.
                    </p>
                    <ul class="list-disc ml-6 text-secondary">
                        <li>Prepare your ad content for publishing</li>
                        <li>Coordinate with our team for optimal placement</li>
                        <li>Track the performance of your ad campaign</li>
                        <li>Engage with your audience to maximize impact</li>
                    </ul>
                </section>

                <section class="mb-8">
                    <h2 class="text-lg font-bold text-primary mb-2">
                        Step 5: Measure Results
                    </h2>
                    <p class="text-secondary">
                        After your ad campaign has run its course, it's time to{" "}
                        <strong>measure the results</strong>. Analyze key
                        metrics such as click-through rates, conversion rates,
                        and return on investment (ROI) to gauge the
                        effectiveness of your advertisement. Use this data to
                        refine your future marketing strategies and improve your
                        overall advertising performance.
                    </p>
                    <ul class="list-disc ml-6 text-secondary">
                        <li>Utilize analytics tools to track performance</li>
                        <li>
                            Identify successful strategies and areas for
                            improvement
                        </li>
                        <li>
                            Adjust your advertising tactics based on data-driven
                            insights
                        </li>
                        <li>
                            Continuously monitor and optimize your campaigns
                        </li>
                    </ul>
                </section>

                <section class="mb-8">
                    <h2 class="text-lg font-bold text-primary mb-2">
                        Step 6: Repeat and Refine
                    </h2>
                    <p class="text-secondary">
                        Once you've evaluated the results of your ad campaign,
                        it's time to <strong>repeat and refine</strong>. Use
                        your insights to iterate on your advertising strategy,
                        making adjustments as needed to achieve your marketing
                        goals. Whether it's tweaking your targeting parameters
                        or testing new ad creatives, continuous improvement is
                        key to driving success in advertising.
                    </p>
                    <ul class="list-disc ml-6 text-secondary">
                        <li>
                            Implement changes based on learnings from previous
                            campaigns
                        </li>
                        <li>
                            Experiment with different ad formats and placements
                        </li>
                        <li>
                            Stay informed about industry trends and consumer
                            behavior
                        </li>
                        <li>
                            Regularly review and update your advertising
                            strategy
                        </li>
                    </ul>
                </section>
            </NavigatorPanel>
            <NavigatorPanel title={t("title.settings")} id="settings">
                <AdvertiserUpdateForm />
            </NavigatorPanel>
            <NavigatorPanel title={t("title.guidelines")} id="guidelines">
                <div />
            </NavigatorPanel>
            <NavigatorPanel title={t("title.ad_policy")} id="ad-policy">
                <div />
            </NavigatorPanel>
        </Navigator>
    );
};
