import apiHelper from "../helpers/api.helper";

export const userUtils = {
    login: async ({ username, password }) => {
        return await apiHelper.doApiCall(
            "POST",
            "/login",
            {
                data: {
                    username,
                    password,
                },
            },
            false
        );
    },
    register: async ({ username, email, password, passwordRepeat }) => {
        return await apiHelper.doApiCall(
            "POST",
            "/register",
            {
                data: {
                    username,
                    email,
                    password,
                    passwordRepeat,
                },
            },
            false
        );
    },
    auth: async () => {
        return await apiHelper.doApiCall(
            "POST",
            "/auth",
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("ar-jwt")}`,
                },
            },
            false
        );
    },
    getUser: async (uuid) => {
        return await apiHelper.doApiCall("GET", `/user/${uuid}`);
    },
    update: async (
        uuid,
        {
            password,
            newPassword,
            newPasswordRepeat,
            interests,
            bankAccount,
            mailingAccepted,
        }
    ) => {
        const formData = new FormData();

        if (password !== undefined) {
            formData.append("password", password);
        }

        if (newPassword !== undefined) {
            formData.append("newPassword", newPassword);
        }

        if (newPasswordRepeat !== undefined) {
            formData.append("newPasswordRepeat", newPasswordRepeat);
        }

        if (interests !== undefined) {
            formData.append("interests", interests);
        }

        if (bankAccount !== undefined) {
            formData.append("bankAccount", bankAccount);
        }

        if (mailingAccepted !== undefined) {
            formData.append("mailingAccepted", mailingAccepted);
        }

        return await apiHelper.doApiCall("PUT", `/users/${uuid}`, {
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        });
    },
    getSetupIntent: async () => {
        return await apiHelper.doApiCall("GET", `/setupIntent`);
    },
    delete: async (uuid) => {
        return await apiHelper.doApiCall("DELETE", `/user/${uuid}`);
    },
};
