export const EN_GB = {
    label: {
        firstname: "Firstname",
        lastname: "Lastname",
        email: "E-Mail",
        phone: "Phone",
        company_name: "Company name",
        de_de: "German",
        en_gb: "English",
        logo: "Logo",
        advertisement: "Advertisement",
        prize_pool: "Prize pool",
        tickets: "Tickets",
        participants: "Participants",
        your_tickets: "Your tickets",
        win_chance: "Win chance",
        password: "Password",
        current_password: "Current password",
        new_password: "New password",
        repeat_new_password: "Repeat new password",
        video: "Video",
        website_url: "Website-URL",
        description: "Description",
        tags: "Tags",
        price: "Price",
        price_overview: "Price overview",
        limit: "Limit",
        waiting_for_payment: "Waiting for payment",
        payment_confirmed: "Payment confirmed",
        draft: "Draft",
        waiting_for_approval: "Waiting for approval",
        paused: "Paused",
        live: "Live",
        limit_reached: "Limit reached",
        declined: "Declined",
        status: "Status",
        watches: "Watches",
        website_watches: "Website watches",
        payment_failed: "Payment failed",
        bank_account: "Bank account",
        golden_tickets: "Golden tickets",
        registered_since: "Registered since",
    },
    description: {
        website_url: "Will be displayed in the end-screen of your video",
        description: "Will be displayed in the end-screen of your video",
        campaign_website:
            "Watchers will be asked to visit your website after they watched your ad. Your website will be embedded into adroulette.net, to make sure watchers stay on your website for the selected amount of time",
        campaign_tags:
            "Use our tags to target your audience wisely, we will try our best to match your advertisement with interested watchers",
        bank_account:
            "You need a valid bank account to claim your prize money, you can find more information in our <a class='underline' href='/terms-of-service#payout'>Terms of Service</a>",
    },
    placeholder: {
        loading: "Loading...",
        search: "Search...",
        bank_account: "Enter a valid bank account",
    },
    question: {
        pause_campaign:
            "Your campaign will be paused for as long as you want, you can always decide to take it live again without having to go through the approval process again",
        resume_campaign:
            "Your campaign will be back live again without having to go through the approval process again",
        delete_campaign:
            "Are you sure you want to delete this campaign? This action can't be reverted.",
        delete_account: "Are you sure you want to delete your account?",
        delete_account_advertiser:
            "Your subscription will be cancelled and all your advertisements will be permanently deleted.",
    },
    title: {
        delete_account: "Delete account",
        email_notification: "E-Mail notifications",
        account_settings: "Account settings",
        payment_method_added: "Payment method added",
        advertisers_thank_you:
            "Big shoutout to our advertisers, this wouldn't be possible without you guys",
        publish: "Publish",
        pause_campaign: "Pause campaign",
        resume_campaign: "Resume campaign",
        delete_campaign: "Delete campagin",
        raffle_winner:
            "<span class='text-gradient-primary font-black'>{{value}}</span> won the raffle, congratulations!",
        become_an_advertiser: "Become an Advertiser",
        language: "Language",
        raffles: "Raffles",
        more_raffles: "More raffles",
        participating_raffles: "Ongoing raffles, that you're participating in",
        change_password: "Change password",
        dangerzone: "Danger zone",
        chfge_interests: "Change interests",
        interests: "Interests",
        cwfa: "CWFA",
        create_campaign: "Create your campaign",
        my_account: "My account",
        advertisements: "Your Advertisements",
        countries: "Countries",
        website: "Your website",
        tags: "Tags",
        legal: "Legal",
        imprint: "Imprint",
        terms_of_service: "Terms of Service",
        data_privacy: "Data Privacy",
        contact: "Contact",
        disclaimer: "Disclaimer",
        limit: "Set limit",
        save_campaign: "Save campaign",
        win_congratulations: "Congratulations, you won!",
        claimed_raffles: "Claimed raffles",
        manage_campaign: "Manage campaign",
        insights: "Insights",
        your_campaigns: "Your campaigns",
        settings: "Settings",
        how_it_works: "How it works",
        guidelines: "Guidelines",
        ad_policy: "Policies",
        monthly_raffle: "Monthly raffle",
    },
    subtitle: {
        advertisements: "Create and manage your advertisements",
    },
    button: {
        unsubscribe: "Unsubscribe",
        subscribe: "Subscribe",
        create_account: "Create account",
        advertisements: "Your Advertisements",
        choose_other_video: "Choose other video",
        choose_video: "Choose video",
        remove: "Remove",
        claim_ticket: "Claim",
        share: "Share",
        claim_golden_ticket: "Claim",
        watch: "Watch",
        resume: "Resume",
        publish: "Publish",
        pause: "Pause",
        save: "Save",
        delete: "Delete",
        delete_account: "Delete account",
        change_password: "Change password",
        logout: "Logout",
        preview: "Preview",
        next: "Next",
        create: "Create",
        back: "Back",
        send_email: "Send an E-Mail",
        set: "Set",
        cancel: "Cancel",
        claim_prize: "Claim prize",
        new_campaign: "New campaign",
        yes: "Yes",
        yes_claim_ticket: "Yes, claim ticket",
        no: "No, thank you",
        seconds: "{{value}} seconds",
        one_minute: "1 minute",
        week: "Week",
        month: "Month",
        choose_image: "Choose image",
    },
    text: {
        email_notification:
            "Feel free to subscribe and unsubscribe to our e-mail notifications at any time. We have to inform you that you won't be notified about your winnings anymore, if you choose to disable your e-mail notifications it'd be really sad if you miss out on your well deserved cash prize",
        payment_method_added:
            "Your payment method has been added to your account, it might take a moment for your subscription to become active",
        no_active_advertisers:
            "There are currently no active advertisers and thus no advertisements to watch, please check again later for updates",
        publish_campaign_1:
            "Your campaign will go through our approval process which takes about one to three days. Make sure your campaign follows our guidelines",
        publish_campaign_2: "Your payment method will be charged {{value}}",
        better_luck_next_time:
            "We're sorry to hear that it wasn't you. We have plenty of more raffles for you to join, maybe next time you'll have better luck",
        accept_terms:
            "I have read and accept the <a href='/terms-of-service' target='_blank' class='underline'>Terms of Service</a>",
        become_an_advertiser:
            "Grow your audience and give something back to your community with your own advertisements on adroulette.net",
        become_an_advertiser_1:
            "Let your audience know who you are and what you have to offer. If you need some tips, check out our <a href='/play/account#guidelines' target='_blank' class='underline'>Guidelines</a>",
        become_an_advertiser_2:
            "We still need some information from you, you're almost good to go!",
        days_hours_minutes_seconds_left:
            "<span class='font-bold'>{{days}} days</span>, <span class='font-bold'>{{hours}} hours</span>, <span class='font-bold'>{{minutes}} minutes</span> and <span class='font-bold'>{{seconds}} seconds</span> left",
        hours_minutes_seconds_left:
            "<span class='font-bold'>{{hours}} hours</span>, <span class='font-bold'>{{minutes}} minutes</span> and <span class='font-bold'>{{seconds}} seconds</span> left",
        minutes_seconds_left:
            "<span class='font-bold'>{{minutes}} minutes</span> and <span class='font-bold'>{{seconds}} seconds</span> left",
        seconds_left: "<span class='font-bold'>{{seconds}} seconds</span> left",
        of_watches: "of {{value}} watches",
        claim_prize:
            "You can now claim your prize of <span class='text-cash'>{{value}}</span>",
        no_raffles_available:
            "Currently, there are no other open raffles available. Please check again later for updates.",
        campaign_declined:
            "<p>We are sorry to let you know that your campaign was declined with the following reason:</p><p class='my-2 font-bold ml-4 italic'>{{value}}</p><p>Please make sure that your campaign follows our <a href='#' class='underline'>advertisement guidelines</a>.</p><p>After you have updated your campaign we will happily review it again!</p>",
        payment_failed:
            "The payment for your subscription failed, please add a valid payment method",
        campaign_waiting_for_approval:
            "Your campaign is currently undergoing review by our team to ensure compliance with our <a href='#' class='underline'>advertisement guidelines</a>. This process typically takes 1-3 days. Thank you for your patience as we strive to maintain quality standards.",
        campaign_draft:
            "Your advertisement is currently a draft, tailor your advertisement to your demand and publish it",
        ad_website_info:
            "Make sure adroulette.net is permitted to display your website in an iframe. You can test your website in the preview",
        monthly_raffle:
            "You can join our monthly raffle by acquiring golden tickets from visiting sponsors websites",
        delete_account:
            "After deletion, all account data, and everything related to this account will be whiped out of our system. This means you will not be able to participate in any upcoming raffles, withdraw money or accessing the account in gernal anymore.",
        no_data_available: "No data available",
        per_watch: "{{value}} / watch",
        per_website_watch: "{{value}} / website watch",
        watches: "{{value}} watches",
        uploading: "Uploading...",
        disclaimer:
            "We value your time, and so should you. We know it's tempting to win a cash prize, but sometimes it's good to take a break from watching ads and to do something more meaningful. Explore, meet your loved ones, be productive, whatever makes you happy",
        unlimited: "Unlimited",
        volume: "Volume: <b>{{value}}</b>",
        campaign_to_draft:
            "Your campaign will be put back into draft mode, if you upload a new video",
        waiting_for_payment:
            "Your request is being processed, it usually takes us 1 - 3 days. We will try our best to transfer the prize money to your bank account as soon as possible",
        payment_confirmed:
            "We sent your money on the way, it should be arriving on your bank account the following days",
        valid_bank_account_needed:
            "You need a valid bank account to claim your prize money, you can enter it in the account settings",
        welcome_back_1:
            "Welcome back <span class='text-gradient-primary'>{{value}}</span>, good to see you again!",
        welcome_back_2:
            "Welcome back <span class='text-gradient-primary'>{{value}}</span>, was machen Sachen?",
        visit_website_question:
            "Wow, you found a <span class='text-gradient-gold'>golden ticket</span>! Would you like to visit our sponsors website to claim it?",
        golden_ticket_info:
            "With golden tickets you can enter our monthly raffle and have a chance to start off in a good year with a big cash prize!",
        ad_seconds_left: "Advertisement, {{value}} seconds left...",
    },
    validate: {
        user_interests:
            "You must choose at least 3 and a maximum of 5 interests",
        limit: "The limit must be higher than the current watches",
    },

    INTEREST_ELECTRONICS: "Electronics",
    INTEREST_FASHION: "Fashion",
    INTEREST_BOOKS: "Books",
    INTEREST_FOOD: "Food",
    INTEREST_TRAVEL: "Travel",
    INTEREST_MUSIC: "Music",
    INTEREST_SPORTS: "Sports",
    INTEREST_HEALTH: "Health",
    INTEREST_HOME: "Home",
    INTEREST_BEAUTY: "Beauty",
    INTEREST_ART: "Art",
    INTEREST_TECHNOLOGY: "Technology",
    INTEREST_OUTDOORS: "Outdoors",
    INTEREST_TOYS: "Toys",
    INTEREST_PETS: "Pets",
    INTEREST_EDUCATION: "Education",
    INTEREST_FITNESS: "Fitness",
    INTEREST_CRAFTS: "Crafts",
    INTEREST_MOVIES: "Movies",
    INTEREST_AUTOMOTIVE: "Automotive",
    INTEREST_GARDENING: "Gardening",
    INTEREST_JEWELRY: "Jewelry",
    INTEREST_COOKING: "Cooking",
    INTEREST_PHOTOGRAPHY: "Photography",
    INTEREST_COLLECTIBLES: "Collectibles",
    INTEREST_TOOLS: "Tools",
    INTEREST_GIFTS: "Gifts",
    INTEREST_FURNITURE: "Furniture",
    INTEREST_PARTY: "Party",
    INTEREST_OFFICE: "Office",
    INTEREST_BABY: "Baby",
    INTEREST_VINTAGE: "Vintage",
    INTEREST_HOBBIES: "Hobbies",
    INTEREST_DIY: "DIY",
    INTEREST_GADGETS: "Gadgets",
    INTEREST_WELLNESS: "Wellness",
    INTEREST_ACCESSORIES: "Accessories",
    INTEREST_ORGANIC: "Organic",
    INTEREST_GAMING: "Gaming",
    INTEREST_DECORATION: "Decoration",
    INTEREST_NUTRITION: "Nutrition",
    INTEREST_COSMETICS: "Cosmetics",
    INTEREST_FOOTWEAR: "Footwear",
    INTEREST_APPS: "Apps",
    INTEREST_INVESTING: "Investing",

    COUNTRY_DE: "Germany",
    COUNTRY_TR: "Türkiye",

    ERROR_COULD_NOT_CREATE_ADVERTISER:
        "Advertiser account could not be created, please try again later",
    ERROR_ADVERTISER_ALREDY_EXISTS: "You already have an advertiser account",
    ERROR_CAMPAIGN_LIMIT_REACHED:
        "You've reached your maximum amount of campaigns",
    ERROR_NETWORK:
        "There has been a network issue, make sure you have an internet connection or try again later",
    ERROR_API_RATE_LIMIT_EXCEEDED:
        "You have been blocked temporarily for making too many requests, please try again later",
    ERROR_INVALID_FILTERS: "Invalid filters, please try again later",
    ERROR_LOGIN_INCORRECT:
        "The entered username or password was incorrect, please try again.",
    ERROR_DELETE:
        "An error occurred during the deletion process. Please try again later.",
    ERROR_VIDEO_TOO_LARGE: "The video has to be 20 mb or lower in size",
    ERROR_VIDEO_TOO_LARGE_DETAIL:
        "This video exceeds our size limits: {{value}}/20 mb",
    ERROR_NO_CAMPAIGN_FOUND:
        "There are currently no running ads, please try again later",
    ERROR_PASSWORD_MATCH: "The entered passwords did not matcfh",
    ERROR_INCORRECT_PASSWORD: "The entered password was incorrect",
    ERROR_MISSING_FIELDS: "Please fill in all required fields",
    ERROR_PASSWORD_TOO_SHORT: "Your password must have at least 12 characters",
    ERROR_INVALID_INTERESTS: "The entered interests are not valid",
    ERROR_WRONG_VIDEO_FORMAT:
        "This video has an invalid format ({{value}}), allowed types are video/mp4",
    ERROR_VIDEO_TOO_LONG: "The video has to be shorter than 30 seconds",
    ERROR_VIDEO_TOO_SHORT: "The video has to be longer than 5 seconds",
    ERROR_INVALID_AD_KEY: "Ad key is invalid, please try with another ad",
    ERROR_WATCH_VERIFICATION_FAILED:
        "The signature verification failed, please try again with another ad",
    ERROR_INTERNAL: "Something went wrong, please try again later",
    ERROR_BANK_ACCOUNT_NOT_SET:
        "Please add a valid bank account for your account",
    ERROR_RAFFLE_NOT_FOUND: "Sorry, this raffle could not be found",
    ERROR_FORBIDDEN: "You are not authorized to do this",
    ERROR_RAFFLE_ALREADY_CLAIMED: "This raffle has already been claimed",
    ERROR_MISSING_BANK_ACCOUNT: "This user has no bank account set",
    ERROR_WEBSITE_ALREADY_CLAIMED: "You claimed this website visit already",
    ERROR_WATCH_ALREADY_CLAIMED: "You claimed this advertisement watch already",
    ERROR_GOLDEN_TICKET_NEEDED: "You need a golden ticket to join this raffle",

    SUCCESS_DELETE_CAMPAIGN: "Campaign deleted",
    SUCCESS_UPDATE: "Saved",
    SUCCESS_CLAIM_TICKET: "Ticket claimed",
    SUCCESS_LOGIN: "Welcome back!",
    SUCCESS_RAFFLE_CLAIMED: "Prize claimed",
    SUCCESS_CREATE: "Created",
    SUCCESS_ACCOUNT_DELETE: "Account deleted",
};
